const { ethers } = require('./ethers')
const CONSTS = require('./const')
const ABI_ERC20 = require('./abi/erc20.json')
const ABI_CONKISCARDS = require('./abi/conkiscards.json')

function getJsonProvider({ network }) {
    return new ethers.JsonRpcProvider(network.rpcUrls[0], {
        chainId: Number(network.chainId),
        name: network.chainName,
    })
}

function getContract({ provider, contract_address, abi }) {
    return new ethers.Contract(contract_address, abi, provider)
}

function getContractErc20({ provider, contract_address }) {
    return getContract({ provider, contract_address, abi: ABI_ERC20 })
}

function getContractConkisCards({ provider, contract_address }) {
    return getContract({ provider, contract_address, abi: ABI_CONKISCARDS })
}

async function getBalance({ provider, address, decimals }) {
    const balance = await provider.getBalance(address)
    return ethers.formatUnits(balance, decimals)
}

async function getBalanceToken({
    provider,
    address,
    decimals,
    contract_address,
}) {
    const contract = getContractErc20({ provider, contract_address })
    // const decimals = await contract.decimals()
    const balance = await contract.balanceOf(address)
    return ethers.formatUnits(balance, decimals)
}

async function sendCoins({ signer, address_to, amount, decimals }) {
    const value = ethers.parseUnits(amount, decimals)
    const receipt = await signer.sendTransaction({
        to: address_to,
        value,
    })
    return receipt
}

async function sendTokens({
    signer,
    address_to,
    amount,
    decimals,
    contract_address,
}) {
    // const erc20Abi = [
    //     'function transfer(address to, uint256 amount) external returns (bool)',
    // ]
    // const contract = new ethers.Contract(contract_address, erc20Abi, signer)
    const contract = getContractErc20({ provider: signer, contract_address })
    const value = ethers.parseUnits(amount, decimals)
    const tx = await contract.transfer(address_to, value)
    const receipt = await tx.wait()
    return receipt
}

async function getTxsRpc({ provider, address, fromBlock = 0, toBlock = 0 }) {
    const txs = []
    for (let blockNumber = toBlock; blockNumber >= fromBlock; blockNumber--) {
        const block = await provider.getBlock(blockNumber, true)
        block.prefetchedTransactions.forEach((tx) => {
            if (
                tx.from.toLowerCase() === address.toLowerCase() ||
                tx.to.toLowerCase() === address.toLowerCase()
            ) {
                txs.push(tx)
            }
        })
    }
    return txs
}

// UTILS
// UTILS
// UTILS

function getShorterAddress(addr, left = 8, right = 5) {
    return `${addr.slice(0, left)} ... ${addr.slice(-right)}`
}

function formatNumberDecimals(number, decimals) {
    const rounded = parseFloat(number.toFixed(decimals))
    return rounded === parseInt(rounded, 10)
        ? rounded.toString()
        : rounded.toFixed(decimals)
}

function formatNumberPrice(number, price) {
    const decimals = String(Math.round(price)).length + 1
    return formatNumberDecimals(number, decimals)
}

module.exports = {
    ...CONSTS,
    ethers,
    // tx / requests
    getBalance,
    getBalanceToken,
    getTxsRpc,
    sendCoins,
    sendTokens,
    getContract,
    getContractErc20,
    getContractConkisCards,
    // utils / pure
    getJsonProvider,
    getShorterAddress,
    formatNumberDecimals,
    formatNumberPrice,
}
