// https://chainlist.org/?chain=1&search=telos
// https://web3-rpc-debug.vercel.app/

const CHAIN = {
    ETHEREUM: '0x1',
    BEAM: '0x10f1',
    TELOS: '0x28',
    BEAM_TESTNET: '0x3419',
    TELOS_TESTNET: '0x29',
}

const CHAINS = {
    [CHAIN.ETHEREUM]: {
        chainId: CHAIN.ETHEREUM,
        chainName: 'Ethereum',
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io'],
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
        },
    },
    [CHAIN.BEAM]: {
        chainId: CHAIN.BEAM,
        chainName: 'Beam',
        rpcUrls: ['https://build.onbeam.com/rpc'],
        blockExplorerUrls: ['https://subnets.avax.network/beam'],
        nativeCurrency: {
            name: 'BEAM',
            symbol: 'BEAM',
            decimals: 18,
        },
    },
    [CHAIN.TELOS]: {
        chainId: CHAIN.TELOS,
        chainName: 'Telos',
        rpcUrls: ['https://mainnet.telos.net/evm'],
        blockExplorerUrls: ['https://teloscan.io'],
        nativeCurrency: {
            name: 'TELOS',
            symbol: 'TLOS',
            decimals: 18,
        },
    },
    // [CHAIN.BEAM_TESTNET]: {
    //     chainId: CHAIN.BEAM_TESTNET,
    //     chainName: 'Beam Testnet',
    //     rpcUrls: ['https://build.onbeam.com/rpc/testnet'],
    //     blockExplorerUrls: ['https://subnets-test.avax.network/beam'],
    //     nativeCurrency: {
    //         name: 'BEAM',
    //         symbol: 'BEAM',
    //         decimals: 18,
    //     },
    // },
    // [CHAIN.TELOS_TESTNET]: {
    //     chainId: CHAIN.TELOS_TESTNET,
    //     chainName: 'Telos Testnet',
    //     rpcUrls: ['https://testnet.telos.net/evm'],
    //     blockExplorerUrls: ['https://testnet.teloscan.io'],
    //     nativeCurrency: {
    //         name: 'TELOS',
    //         symbol: 'TLOS',
    //         decimals: 18,
    //     },
    // },
}

const COINS = [
    {
        id: 3,
        cmk_id: 825, // coinmarketcap
        name: 'USDT (Ethereum)',
        symbol: 'USDT',
        decimals: 6,
        chain_id: CHAIN.ETHEREUM,
        connect: false,
        payable: true,
        mintable: false,
        contract_address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    {
        id: 4,
        cmk_id: 3408, // coinmarketcap
        name: 'USDC (Ethereum)',
        symbol: 'USDC',
        decimals: 6,
        chain_id: CHAIN.ETHEREUM,
        connect: false,
        payable: true,
        mintable: false,
        contract_address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    },
    {
        id: 5,
        cmk_id: 28298, // coinmarketcap
        name: 'Beam (Ethereum)',
        symbol: 'BEAM',
        decimals: 18,
        chain_id: CHAIN.ETHEREUM,
        connect: false,
        payable: true,
        mintable: false,
        contract_address: '0x62D0A8458eD7719FDAF978fe5929C6D342B0bFcE',
    },
    {
        id: 2,
        cmk_id: 28298, // coinmarketcap
        name: CHAINS[CHAIN.BEAM].chainName,
        symbol: 'BEAM',
        decimals: 18,
        chain_id: CHAIN.BEAM,
        connect: true,
        payable: false,
        mintable: true,
    },
    {
        id: 1,
        cmk_id: 4660, // coinmarketcap
        name: CHAINS[CHAIN.TELOS].chainName,
        symbol: 'TLOS',
        decimals: 18,
        chain_id: CHAIN.TELOS,
        connect: true,
        payable: false,
        mintable: true,
    },
    //{
    //     id: 101,
    //     cmk_id: 4660, // coinmarketcap
    //     name: CHAINS[CHAIN.TELOS_TESTNET].chainName,
    //     symbol: 'TLOS',
    //     decimals: 18,
    //     chain_id: CHAIN.TELOS_TESTNET,
    // },
]

const SMART_CONTRACTS = {
    [CHAIN.BEAM]: {
        CARDS: '0x17D806AefC6E805fd276623396e133d999a28c80',
    },
    [CHAIN.TELOS]: {
        CARDS: '0xba48921b13E972756Cf5105C905BA990e8D54862',
    },
    // [CHAIN.TELOS_TESTNET]: {
    //     CARDS: '0xDe7B0C87a37B2B9f3a7D1C3080BDe64eEe9B8e02',
    // },
}

module.exports = {
    CHAIN,
    CHAINS,
    COINS,
    SMART_CONTRACTS,
}
